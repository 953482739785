import 'babel-polyfill';
import 'reactApp/fonts.css';
import 'semantic-ui-css/semantic.min.css';

import { applyB2BRebranding } from 'Cloud/Application/Rebranding';
import { Rubles } from 'Cloud/Application/Rubles';
import user from 'Cloud/Application/User';
import {
    ERROR_PAGE_STATUS,
    IS_B2B_BIZ_USER,
    IS_PHONE_BROWSER,
    IS_PUBLIC,
    PUBLIC_ERROR_PAGE_STATUS,
    USER,
} from 'reactApp/appHelpers/configHelpers';
import { VKTeamsClient } from 'reactApp/modules/vkTeam/vkTeam.module';
import { EErrorPageAction, sendErrorPageAnalytics } from 'reactApp/sections/ErrorPage/ErrorPage.analytics';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { ESiteZoneErrorPage } from 'reactApp/ui/AdvBanners/Adv.types';
import { EButtonAction, sendDisableAdsAnalytics } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton.analytics';
import {xray} from "./lib/xray";
import { changeLogoIfDocDomain } from 'server/helpers/errors/changeLogoIfDocDomain';

const isPhone = IS_PHONE_BROWSER || window.matchMedia('(max-width: 768px)').matches;
const errorStatus = PUBLIC_ERROR_PAGE_STATUS || ERROR_PAGE_STATUS;

const getDwhSource = () => {
    if (IS_PUBLIC) {
        return 'public';
    }

    // На статичных (nginx) страницах ошибок мы не знаем source.
    if (isPhone) {
        return 'touch';
    }

    return 'web';
};

const initButtonClick = () => {
    const button = document.querySelector('.js-error-page-button');
    const source = getDwhSource();

    if (!button) {
        return;
    }

    button.addEventListener('click', (e) => {
        sendErrorPageAnalytics({
            action: EErrorPageAction.CLICK,
            number_error: errorStatus,
            source,
        });
    });
};

const initB2BRebranding = (user) => {
    if (!IS_B2B_BIZ_USER) {
        return;
    }

    const isTarm = user && 'is_tarm' in user.cloudflags && user.cloudflags.is_tarm;
    applyB2BRebranding(isTarm);
};

const initDisableAdAnalytics = () => {
    const button = document.querySelector('.js-disable-button');

    if (!button) {
        return;
    }

    sendDisableAdsAnalytics({
        action: EButtonAction.SHOW,
        source: errorStatus,
        place: 'error-page',
        platform: isPhone ? 'touch' : 'desktop',
    });

    button.addEventListener('click', () => {
        sendDisableAdsAnalytics({
            action: EButtonAction.CLICK,
            source: errorStatus,
            place: 'error-page',
            platform: isPhone ? 'touch' : 'desktop',
        });
    });
};

const fetchUser = async () => {
    if (USER) {
        return USER;
    }

    // Фолбек для статичных (nginx) страниц ошибок.
    try {
        const tokenRequest = await fetch('/api/v3/tokens/csrf', { method: 'POST' });
        const { token } = await tokenRequest.json();

        if (token) {
            const userRequest = await fetch('/api/v2/user', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'x-csrf-token': token,
                },
            });
            const userInfo = await userRequest.json();

            return userInfo?.body;
        }

        return;
    } catch (error) {
        console.error(error);
    }
};

const showAdvDesktop = () => {
    const element = document.querySelector('.js-right-block-error-page');
    element?.classList.add('js-right-block-error-page--visible');
};

const showAdv = (isSuperAppWebView) => {
    const errorStatusText = errorStatus && EStatus[errorStatus];
    const siteZone = errorStatusText && ESiteZoneErrorPage[errorStatusText];
    const source = getDwhSource();

    if (isPhone) {
        Rubles?.updateErrorPagePhoneTopAdv(+siteZone);
    } else {
        showAdvDesktop();
        Rubles?.updateErrorPageRightColAdv(+siteZone);
    }

    initDisableAdAnalytics();

    sendErrorPageAnalytics({
        action: EErrorPageAction.SHOW,
        number_error: errorStatus,
        source,
    });

    if (isSuperAppWebView){
        xray.send('public', {
            p: 'cloud-integration',
            i: `error-${errorStatus}`,
        });
    }
};

const checkShowAdv = (user) => {
    const { account_type, cloudflags } = user || {};

    if (account_type === 'pdd') {
        return false;
    }

    return !((cloudflags?.CORP && user?.flags?.hide_ad_in_mail_web) || cloudflags?.PAID_ACCOUNT || cloudflags?.FAMILY);
};

const initAdvApp = async (isSuperAppWebView) => {
    const user = await fetchUser();
    initButtonClick();
    initB2BRebranding(user);

    if (checkShowAdv(user)) {
        showAdv(isSuperAppWebView);
    }
};

try {
    //Меняем лого на ошибках в Документах
    changeLogoIfDocDomain();

    const queryParams = new URLSearchParams(window?.location?.search);
    const fromDeeplink = queryParams.get('from_deeplink');
    const isWebView = queryParams.get('webview') === 'true';
    const isSuperAppWebView = !!(fromDeeplink && isWebView);
    // Фикс прелоадера.
    window.ContentLoader?.setJSLoaded?.();
    // функции ниже необходимо заинитить, чтобы заработал rubles() без ошибок.
    user();
    initAdvApp(isSuperAppWebView);

    VKTeamsClient.applyAppThemeSettings({ staticPage: true })
        .hideVKWorkspaceLogo({ staticPage: true })
        .notifyAppReady({ isReady: true, staticPage: true });

    // CLOUDWEB-17690: Показываем кнопки в шаблоне ошибки, если ошибка открыта не в вебвью супераппа
    window.addEventListener('load', () => {
        if (!isSuperAppWebView) {
            const descriptionButton = document.querySelector('#error-page__button');
            descriptionButton?.classList.remove('errorPageDisableElement');
            const downloadbButton = document.querySelector('#error-page__download-button');
            downloadbButton?.classList.remove('errorPageDisableElement');
            const textWithLink = document.querySelector('#error-page__text-with-link');
            textWithLink?.classList.remove('errorPageDisableElement');
            const logo = document.querySelector('#mobile__logo-component');
            logo?.classList.remove('errorPageDisableCursorEvents');
        }
    });
} catch (error) {
    VKTeamsClient.notifyAppReady({ isReady: false, staticPage: true });
    console.error(error);
}
