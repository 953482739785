/**
 * Функция добавляет класс в crossNav, если пользователь находится на домене Документов
 */
export const changeLogoIfDocDomain = (): void => {
    const hostname = window.location.hostname;

    const headline = document.querySelector('#staticErrorHeadline');
    if (!headline) {
        return;
    }

    if (hostname === 'doc.mail.ru' || hostname.match(/doc\d*\.mini-mail\.ru$/g)) {
        headline.classList.add('headline_doc');
    }

    headline.classList.remove('logoHidden');
};
